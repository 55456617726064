import React from 'react'
import Layout from "../components/layout"

const AboutSite = () => (
  <Layout>
    <div style={{fontSize: '2rem', textAlign: 'center', padding: '35vh 0', background: '#fff', color: '#333', fontWeight: 'bold'}}>
      Made by Otto; Powered by <a href="https://www.gatsbyjs.org/">Gatsby</a>, <a href="https://www.contentful.com/">Contentful</a> and <a href="https://www.netlify.com/">Netlify</a>. 
    </div>
  </Layout>
)

export default AboutSite;
